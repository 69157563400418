<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card ref="cardReferendum">
          <v-card-title>
            Aktuálne hlasovanie o otázke v NAŠOM REFERENDE
          </v-card-title>
          <div v-if="referendums.length > 0">
            <v-card-text
              v-for="referendum in referendums"
              v-bind:key="referendum.id"
            >
              <strong class="ma-1">Naše Referendum číslo {{ referendum.id }}</strong>

              <v-card-title v-if="referendum.name">
                <v-tooltip
                  top
                  v-if="referendum.tooltip"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    > {{ referendum.name }}
                    </span>
                  </template>
                  <span>{{ referendum.tooltip }}</span>
                </v-tooltip>
                <span v-else>{{ referendum.name }}</span>
              </v-card-title>

              <v-card-subtitle>
                <countdown :time="getCountdownTime(referendum.end_date)">
                  <template slot-scope="props">Do konca zostáva / dní: <b>{{ props.days }}</b> / hodín: <b>{{ props.hours
                  }}</b> /
                    minút: <b>{{ props.minutes }}</b> / sekúnd: <b>{{ props.seconds }}</b>
                  </template>
                </countdown>
              </v-card-subtitle>
              <!-- <v-card-subtitle>Prebieha od {{ referendum.start_date | moment("DD.MM.YYYY HH:mm:ss") }} do {{
                    referendum.end_date | moment("DD.MM.YYYY HH:mm:ss") }}</v-card-subtitle> -->
              <v-card
                v-for="(question, index) in referendum.questions"
                v-bind:key="question.id"
                class="mb-3"
              >
                <v-row
                  justify="space-between"
                  class="ma-1"
                >
                  <v-col cols="auto">
                    <strong>Otázka č. {{ index + 1 }}</strong>
                  </v-col>
                  <v-col
                    cols="auto"
                    class="mr-15"
                  >
                    <router-link v-if="question.enabled_discussion" :to="'referendum/' + referendum.id + '/question/' + question.id + '/discussion'">DISKUSIA K OTÁZKE ({{question.comments_total}})</router-link>
                  </v-col>
                  <v-col cols="auto">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="error"
                          size="18"
                          style="margin-bottom: 1px;"
                          v-bind="attrs"
                          v-on="on"
                        >mdi-information-outline</v-icon>
                      </template>
                      <v-card><v-card-text>
                          <p>Výsledok v tomto NAŠOM REFERENDE JE LEN INFORMATíVNY a nie je pre nikoho nijako záväzný!</p>

                          <p>Je to len pomoc pre tých, ktorí takéto informácie privítajú. Mali by to byť aj štátny
                            úradníci a politici (hlavne oni) ale tí to asi nebudú.</p>

                          <p>Vylúčenie zodpovednosti k príspevkom v DISKUSIÁCH K OTÁZKAM a v NAŠICH REFERENDÁCH!</p>

                          <p>Organizátor tohto NÁŠHO REFERENDA sa dištancuje od obsahu vyjadrení osôb, ktoré prezentujú na
                            tomto fóre svoje názory a plnú zodpovednosť za ich obsah preberá osoba, ktorá tento príspevok
                            na tejto platforme zverejnila.</p>
                        </v-card-text></v-card>
                    </v-menu>

                  </v-col>
                </v-row>

                <v-card-title class="primary--text">
                  <v-row justify="space-between">
                    <v-col cols="1">
                    </v-col>
                    <v-col
                      cols="10"
                      align="center"
                    >
                      <v-tooltip
                        top
                        v-if="question.tooltip"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                            class="text-h4"
                          > {{ question.text }}
                          </span>
                        </template>
                        <span>{{ question.tooltip }}</span>
                      </v-tooltip>
                      <span
                        class="text-h4"
                        v-else
                      >{{ question.text }}</span>
                    </v-col>
                    <v-col cols="1">
                      <share-component
                        :name="question.text"
                        :pageUrl="pageUrl"
                      ></share-component>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-card-actions v-if="isAuthenticated && question.user_voted">
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="question.user_voted_type == 'YES'"
                    small
                    outlined
                    color="success"
                  ><span v-if="gender == 'MAN'">Hlasoval</span><span v-else>Hlasovala</span>&nbsp;si&nbsp;<strong>ÁNO</strong></v-btn>
                  <v-btn
                    v-if="question.user_voted_type == 'NO'"
                    small
                    outlined
                    color="error"
                  ><span v-if="gender == 'MAN'">Hlasoval</span><span v-else>Hlasovala</span>&nbsp;si&nbsp;<strong>NIE</strong></v-btn>
                  <v-btn
                    v-if="question.user_voted_type == 'ABSTAIN'"
                    small
                    outlined
                    color="primary"
                  ><strong><span v-if="gender == 'MAN'">Zdržal</span><span v-else>Zdžala</span>&nbsp;si
                      sa</strong></v-btn>
                  <v-btn
                    v-if="question.user_voted_type == 'CANCELLED'"
                    small
                    outlined
                    color="grey"
                  ><strong><span v-if="gender == 'MAN'">Zrušil</span><span v-else>Zrušila</span>&nbsp;si
                      hlas</strong></v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>

                <v-card-actions>
                  <v-row
                    v-if="!$vuetify.breakpoint.smAndDown"
                    no-gutters
                  >
                    <v-col
                      cols="12"
                      class="d-flex justify-center align-center"
                    >
                      <fake-button
                        color="success"
                        small
                        btnClass="mr-2"
                        @click="voteQuestion('YES', question.id)"
                        text="Hlasujem ÁNO"
                      ></fake-button>
                      <fake-button
                        color="error"
                        small
                        btnClass="mr-2"
                        @click="voteQuestion('NO', question.id)"
                        text="Hlasujem NIE"
                      ></fake-button>
                      <fake-button
                        color="primary"
                        small
                        btnClass="mr-2"
                        @click="voteQuestion('ABSTAIN', question.id)"
                        text="Zdržím sa"
                      ></fake-button>
                      <fake-button
                        v-if="question.user_voted"
                        color="grey"
                        small
                        dark
                        @click="unvoteQuestion(question.id)"
                        text="Zruším môj hlas"
                      ></fake-button>
                    </v-col>
                  </v-row>

                  <v-row v-else>
                    <v-col
                      cols="12"
                      class="d-flex justify-center align-center"
                    >
                      <fake-button
                        small
                        color="success"
                        btnClass="mr-2"
                        btnStyle="width: 108px;"
                        @click="voteQuestion('YES', question.id)"
                        text="Hlasujem ÁNO"
                      ></fake-button>
                      <fake-button
                        small
                        color="error"
                        btnStyle="width: 108px;"
                        @click="voteQuestion('NO', question.id)"
                        text="Hlasujem NIE"
                      ></fake-button>
                    </v-col>
                    <v-col
                      cols="12"
                      class="d-flex justify-center align-center"
                    >
                      <fake-button
                        small
                        color="primary"
                        btnClass="mr-2"
                        btnStyle="width: 78px;"
                        @click="voteQuestion('ABSTAIN', question.id)"
                        text="Zdržím sa"
                      ></fake-button>
                      <fake-button
                        v-if="question.user_voted"
                        small
                        color="grey"
                        dark
                        btnStyle="width: 128px;"
                        @click="unvoteQuestion(question.id)"
                        text="Zruším môj hlas"
                      ></fake-button>
                    </v-col>
                  </v-row>
                </v-card-actions>
                <v-card-title class="text-subtitle-1 font-weight-bold">
                  Okamžité výsledky hlasovania
                </v-card-title>
                <v-card-text>
                  <poll-results
                    :referendum="referendum"
                    :question="question"
                    oneRow
                  ></poll-results>
                </v-card-text>
              </v-card>
            </v-card-text>
          </div>
          <div v-else>
            <v-card-text>
              Momentálne neprebiehajú žiadne Naše Referendá, pridaj novú otázku, ktorá môže byť zaradená do Nášho
              Referenda.
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                História všetkých Našich Referend
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <data-table-paging-filtering
                  :headers="headersReferendumArchive"
                  :get-items-func="getReferendumArchive"
                  :item-row-background-func="itemRowBackgroundFunc"
                  :show-expand="true"
                  :dense="false"
                  :sync="syncReferendumArchive"
                  uniqueID="polls-referendumarchive"
                >
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <v-card
                        v-for="question in item.questions"
                        v-bind:key="question.id"
                        class="ma-1"
                      >
                        <v-card-title class="primary--text">{{ question.text }} <v-spacer></v-spacer><router-link :to="'referendum/' + item.id + '/question/' + question.id + '/discussion'">DISKUSIA K
                            OTÁZKE ({{question.comments_total}})</router-link>
                        </v-card-title>
                        <v-card-actions v-if="isAuthenticated && question.user_voted">
                          <v-spacer></v-spacer>
                          <v-btn
                            v-if="question.user_voted_type == 'YES'"
                            small
                            outlined
                            color="success"
                          ><span v-if="gender == 'MAN'">Hlasoval</span><span v-else>Hlasovala</span>&nbsp;si&nbsp;<strong>ÁNO</strong></v-btn>
                          <v-btn
                            v-if="question.user_voted_type == 'NO'"
                            small
                            outlined
                            color="error"
                          ><span v-if="gender == 'MAN'">Hlasoval</span><span v-else>Hlasovala</span>&nbsp;si&nbsp;<strong>NIE</strong></v-btn>
                          <v-btn
                            v-if="question.user_voted_type == 'ABSTAIN'"
                            small
                            outlined
                            color="primary"
                          ><strong><span v-if="gender == 'MAN'">Zdržal</span><span v-else>Zdžala</span>&nbsp;si
                              sa</strong></v-btn>
                          <v-btn
                            v-if="question.user_voted_type == 'CANCELLED'"
                            small
                            outlined
                            color="grey"
                          ><strong><span v-if="gender == 'MAN'">Zrušil</span><span v-else>Zrušila</span>&nbsp;si
                              hlas</strong></v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>

                        <v-card-text>
                          <poll-results
                            :referendum="item"
                            :question="question"
                            oneRow
                          ></poll-results>
                        </v-card-text>
                      </v-card>
                    </td>
                  </template>

                  <template v-slot:item.start_date="{ item }">
                    {{ item.start_date | moment("DD.MM.YYYY HH:mm:ss") }}
                  </template>

                  <template v-slot:item.end_date="{ item }">
                    {{ item.end_date | moment("DD.MM.YYYY HH:mm:ss") }}
                  </template>

                </data-table-paging-filtering>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Nová otázka do Nášho Referenda</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              light
              color="success"
              rounded
              class="pa-5"
              @click="createQuestion(newQuestion)"
            >Odoslať otázku<br />na
              schválenie</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Navrhované znenie otázky</v-list-item-title>
              <v-list-item-subtitle>
                <v-textarea
                  label="Sem pridaj znenie tvojej navrhovanej otázky"
                  rows="1"
                  auto-grow
                  v-model="newQuestion.text"
                ></v-textarea>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                <v-checkbox
                  v-model="newQuestion.anonymous"
                  label="Chcem aby moja otázka bola anonymná (nezobrazí sa pri nej navrhovateľ)"
                ></v-checkbox>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="!newQuestion.anonymous">
            <v-list-item-content>
              <v-list-item-title>Meno</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model="newQuestion.user_first_name"></v-text-field>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="!newQuestion.anonymous">
            <v-list-item-content>
              <v-list-item-title>Mesto/Obec</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model="newQuestion.user_location"></v-text-field>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-alert type="warning">Tvoja otázka bude zverejnená po kontrole jej znenia administrátorom. V prípade, že
          administrátor vyhodnotí tvoju otázku ako nevhodnú, nebude zaradená medzi potenciálne otázky Nášho
          Referenda.</v-alert>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
import Vue from "vue";

import { APIService } from "@/http/APIService.js";
import { mapGetters } from "vuex";

import DataTablePagingFiltering from "@/components/DataTablePagingFiltering.vue";
import PollResults from "@/components/PollResults.vue";
import FakeButton from "@/components/FakeButton.vue";

import moment from 'moment';
import VueCountdown from '@chenfengyuan/vue-countdown';

import ShareComponent from "@/components/ShareComponent.vue";

Vue.component(VueCountdown.name, VueCountdown);

const apiService = new APIService();

Vue.use(require("vue-moment"));

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

export default {
  components: {
    "data-table-paging-filtering": DataTablePagingFiltering,
    "poll-results": PollResults,
    "fake-button": FakeButton,
    ShareComponent
  },

  data: () => ({
    loading: false,
    sort: null,
    referendums: [],
    questionChooses: [],
    myQuestions: [],
    syncMyQuestions: false,
    syncReferendumArchive: false,
    syncQuestionChooseArchive: false,
    sortQuestionFavorite: false,
    user: {},
    filterText: null,

    newQuestion: {
      text: "",
      user_first_name: "",
      user_location: "",
      user: 1,
      anonymous: false
    },

    dialog: false,

    headersMyQuestions: [
      {
        text: "ID",
        value: "id",
      },
      { text: "Vytvorené", value: "created_at" },
      { text: "Počet hlasov vo výbere", value: "likes_count" },
      { text: "Počet ÁNO hlasov v NR ", value: "votes_yes_count" },
      { text: "Počet NIE hlasov v NR ", value: "votes_no_count" },

      { text: "Status", value: "status", width: "15%" }
    ],


    headersReferendumArchive: [
      {
        text: "ID",
        value: "id",
      },
      {
        text: "Názov",
        value: "name"
      },
      { text: "Začiatok", value: "start_date" },
      { text: "Koniec", value: "end_date" },
    ],

    headersQuestionChooseArchive: [
      {
        text: "ID",
        value: "id",
      },
      {
        text: "Názov",
        value: "name"
      },
      { text: "Začiatok", value: "start_date" },
      { text: "Koniec", value: "end_date" },
    ],
  }),

  mounted: function () {
    this.getMe();
    this.getReferendums();
  },

  computed: {
    ...mapGetters("auth", [
      "isAuthenticated",
      "username",
      "isVerified",
      "isVerifiedBank",
      "isVerifiedSmsEmail",
      "isStaff",
      "getMemberStatus",
      "firstName",
      "lastName",
      "gender"
    ]),

    getMobileClasses() {
      if (this.$vuetify.breakpoint.smAndDown)
        return "d-flex justify-center align-center";
      else
        return ""
    },

    pageUrl() {
      return encodeURI(window.location.origin + this.$route.path);
    },
  },

  methods: {
    getCountdownTime(date) {
      return moment(date).valueOf() - Date.now();
    },

    addFavorite(item) {
      this.loading = true;
      apiService.addFavoriteReferendumQuestion(item.id).then(() => {
        this.loading = false;
      });
    },

    removeFavorite(item) {
      this.loading = true;
      apiService.removeFavoriteReferendumQuestion(item.id).then(() => {
        this.loading = false;
      });
    },

    getCardElevation(index) {
      let classes = "mb-3";
      // if (index % 2 == 1) {
      //   classes += " elevation-1";
      // }

      return classes;
    },

    getMe() {
      apiService.getMe().then((response) => {
        this.user = response;
        this.newQuestion.user_first_name = this.user.first_name;
        this.newQuestion.user_location = this.user.address_town;
      });
    },


    itemRowBackgroundFunc() {
      return "";
    },

    voteQuestion(type, id) {
      apiService.voteReferendumQuestion(type, id).then(() => {
        this.getReferendums();
      })
    },

    unvoteQuestion(id) {
      apiService.unvoteReferendumQuestion(id).then(() => {
        this.getReferendums();
      })
    },


    getReferendumArchive(sort, search, limit, offset, filters) {
      return apiService.getReferendumArchive(sort, search, limit, offset, filters);
    },

    getReferendums() {
      apiService.getReferendumsSimple().then((response) => {
        this.referendums = response.results;
      });
    },

    createQuestion(newItem) {
      if (this.newQuestion.text === null || this.newQuestion.text.trim() === "") {
        this.dialog = !this.dialog;
        this.newQuestion.text = "";
        this.newQuestion.user_first_name = this.user.first_name;
        this.newQuestion.user_location = this.user.address_town;

        return;
      }

      if (newItem.anonymous) {
        this.newQuestion.user_first_name = null;
        this.newQuestion.user_location = null;
      }
      apiService.createReferendumQuestion(newItem).then(() => {
        this.syncMyQuestions = !this.syncMyQuestions;
        this.dialog = !this.dialog;
        this.newQuestion.text = "";
      })
    }
  },
};
</script>